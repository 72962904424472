<template>
  <el-menu :mode="mode">
    <el-submenu v-if="isAuth" index="3">
      <template slot="title">
        <div class="el-submenu__title_container">
          <div class="el-submenu__title_icon_container">
            <i class="fa fa-user"></i>
          </div>
        </div>
      </template>
      <client-only>
        <template v-if="isAuth">
          <el-menu-item index="3-1" class="option-user">
            <nuxt-link to="/user/profile">
              <!-- <i class="fa fa-user" /> -->
              <span>{{ $t('navbar.my_account') }}</span>
            </nuxt-link>
          </el-menu-item>

          <el-menu-item index="3-2" class="option-user is-hr">
            <hr />
          </el-menu-item>

          <el-menu-item index="3-3" class="option-user">
            <nuxt-link to="/user/profile?tab=my-profile">{{
              $t('navbar.my_profile')
            }}</nuxt-link>
          </el-menu-item>

          <!-- <el-menu-item index="3-4" class="option-user">
            <nuxt-link to="/user/profile?tab=reservations">{{
              $t('navbar.my_reservations')
            }}</nuxt-link>
          </el-menu-item> -->

          <el-menu-item index="3-5" class="option-user" @click="logout">
            <a>{{ $t('navbar.logOut') }}</a>
          </el-menu-item>
        </template>

        <template v-else>
          <el-menu-item
            index="3-4"
            class="lad-auth-item"
            @click="toggleAuthModal"
          >
            <span>{{ $t('auth.title.login_register') }}</span>
          </el-menu-item>
        </template>
      </client-only>
    </el-submenu>
    <el-menu-item v-else index="3" @click="toggleAuthModal">
      <template slot="title">
        <div v-if="mode === 'horizontal'" class="el-submenu__title_container">
          <div class="el-submenu__title_icon_container">
            <i class="fa fa-user"></i>
          </div>
        </div>
        <div v-else class="el-submenu__title_container">
          <el-button round>{{ $t('auth.btn_login') }}</el-button>
        </div>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

export default {
  name: 'LadNavigationMenuAuth',
  props: {
    mode: { type: String, required: true },
  },
  computed: {
    ...mapGetters(['currentUserAuth']),
  },
  methods: {
    async logout() {
      const strategy = this.$store.state.auth.strategy || 'custom-login'

      await this.$auth
        .logout(strategy) /* .then(() => this.$router.push('/')) */
        .finally(() => {
          const keys = [
            `auth.strategy`,
            `auth._token.${strategy}`,
            `auth.${strategy}.state`,
          ]

          keys.forEach((k) => {
            Cookies.remove(k)

            if (localStorage) {
              localStorage.removeItem(k)
            }
          })
        })
    },
    toggleAuthModal() {
      this.$store.dispatch('modals/toggle', 'auth').catch(() => {})
    },
    toggleTripsModal() {
      this.$store.dispatch('modals/toggle', 'trips')
    },
  },
}
</script>

<style lang="scss">
.lad-navigation--menu--auth {
  .el-menu-item {
    display: flex;
  }

  .el-submenu {
    height: 100%;
    align-self: center;

    &__title {
      display: flex;
      padding: 0;

      &_container {
        display: flex;
        padding: 0 15px !important;

        @media (min-width: 576px) {
          padding: 0 20px !important;
        }
      }

      &_icon_container {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #5b5e63;

        i.fa-user {
          color: #fff;
          font-size: 14px;
        }
      }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      &__title {
        &_icon_container {
          width: 40px;
          height: 40px;
          background: #1f9e49;

          i.fa-user {
            font-size: 20px;
          }
        }
      }
    }
  }

  i.el-submenu__icon-arrow.el-icon-arrow-down {
    display: none !important;
  }
}

.lad-navigation--menu--auth__vertical {
  .el-menu-item {
    background: #fff !important;

    .el-submenu__title_container {
      display: block;
      width: 100%;

      .el-button {
        width: 100%;
        font-size: 16px;
        padding: 16px 20px;
        border-radius: 60px;
      }
    }
  }

  .el-submenu {
    &__title {
      padding: 0 20px !important;
      justify-content: center;

      &_container {
        .el-submenu__title_icon_container {
          background: unset;
        }
      }
    }

    i.el-submenu__icon-arrow.el-icon-arrow-down {
      display: inline-block !important;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .option-user {
    display: block;
    text-align: left;

    i {
      color: #303133 !important;
    }

    i + span {
      margin-left: 5px;
    }

    &.is-hr {
      display: none;
    }
  }
}

.el-menu--horizontal {
  .option-user {
    height: auto !important;
    i + span {
      margin-left: 5px;
    }

    a {
      display: block;
    }
  }
}
</style>
