<template>
  <el-menu-item :index="route.key">
    <a v-if="isExternalLink" :href="route.path" target="_blank">
      <i v-if="hasIcon" :class="route.icon" />
      <span>{{ title }}</span>
    </a>
    <nuxt-link v-else :to="route.path">
      <i v-if="hasIcon" :class="route.icon" />
      <span>{{ title }}</span>
    </nuxt-link>
  </el-menu-item>
</template>

<script>
import { generateTitle } from '@/utils/i18n'
import { validateURL } from '@/utils/validate'

export default {
  name: 'LadNavigationMenuItem',
  props: {
    route: { type: Object, required: true },
  },
  computed: {
    isExternalLink() {
      return validateURL(this.route.path)
    },
    hasIcon() {
      return !!this.route.icon
    },
    title() {
      return this.generateTitle(this.route.meta.title)
    },
  },
  methods: {
    generateTitle,
  },
}
</script>
