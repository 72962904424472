<template>
  <div class="lad-destinations-menu--countries">
    <el-page-header :title="$t('general.go_back')" @back="handleClick">
      <p slot="content" class="menu-title">{{ continent.name }}</p>
    </el-page-header>

    <el-row type="flex" :gutter="20" class="countries-list">
      <el-col v-for="country in continent.countries" :key="country" :span="4">
        <LadDestinationSubmenuItem v-bind="countriesList[country]" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LadDestinationCountries',
  components: {
    LadDestinationSubmenuItem: () =>
      import(
        /* webpackChunkName: "lad-destination-submenu-item" */ './DestinationSubmenuItem'
      ),
  },
  props: {
    continent: {
      type: Object,
      required: true,
      default: () => ({ name: '', countries: [] }),
    },
  },
  computed: {
    ...mapState('settings', {
      countriesList(state) {
        const items = {}

        this.continent.countries.forEach((code) => {
          const country = state.countries.find((t) => t.alpha_3_code === code)

          items[code] = {
            alpha_3_code: country.alpha_3_code,
            slug: country.slug,
            name: country.name,
          }
        })

        return items
      },
    }),
  },
  methods: {
    handleClick() {
      this.$emit('click', null)
    },
  },
}
</script>

<style lang="scss">
.lad-destinations-menu--countries {
  .el-page-header {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    border-bottom: 1px solid #ebeef2;

    h3 {
      margin: 0.8em 0;
      font-size: 1em;
    }
  }

  .countries-list {
    flex-wrap: wrap;

    .el-menu-item {
      padding: 0 !important;

      a {
        display: block;
        padding: 0 10px;
      }
    }
  }
}
</style>
