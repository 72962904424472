var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-menu',{attrs:{"mode":_vm.mode},on:{"select":_vm.handleSelect}},[(_vm.locales.length)?_c('el-submenu',{attrs:{"index":"language"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"language-menu__container"},[_c('span',{staticClass:"language-menu__container_title"},[_vm._v(_vm._s(_vm.$t('general.language')))]),_vm._v(" "),_c('span',{staticClass:"language-menu__container_value"},[_vm._v(_vm._s(_vm.$t(
              // eslint-disable-next-line vue/comma-dangle
              `general.languages_without_country.${_vm.currentLanguage}`
            ))+"\n          ("+_vm._s(_vm.currentLanguageCountry)+")")])])]),_vm._v(" "),_c('client-only',_vm._l((_vm.locales),function(language){return _c('el-menu-item',{key:language.code,attrs:{"disabled":language.code === _vm.locale,"index":language.code}},[(_vm.isTravelWebsite)?[_c('span',{staticClass:"lad-navigation--flag"},[_vm._v(_vm._s(language.flag_code))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(language.name))])]:_c('a',{attrs:{"href":_vm.links[language.code]}},[_c('span',{staticClass:"lad-navigation--flag"},[_vm._v(_vm._s(language.flag_code))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(language.name))])])],2)}),1)],2):_c('el-menu-item',{attrs:{"index":_vm.locale}},[_c('div',{staticClass:"language-menu__container px-20"},[_c('span',{staticClass:"language-menu__container_title"},[_vm._v(_vm._s(_vm.$t('general.language')))]),_vm._v(" "),_c('span',{staticClass:"language-menu__container_value"},[_vm._v(_vm._s(_vm.$t(
            // eslint-disable-next-line vue/comma-dangle
            `general.languages_without_country.${_vm.defaultLanguage}`
          ))+"\n        ("+_vm._s(_vm.defaultLanguageCountry)+")")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }