<template>
  <el-menu :mode="mode">
    <template v-for="route in menu">
      <el-submenu
        v-if="hasChildren(route)"
        :key="`a-${route.key}`"
        :index="route.key"
        popper-class="lad-navigation--menu"
      >
        <template slot="title">
          <i v-if="!!route.icon" :class="route.icon" />
          <span>{{ generateTitle(route.meta.title) }}</span>
        </template>

        <client-only>
          <LadNavigationMenuItem
            v-for="child in route.children"
            :key="child.key"
            :route="child"
          />

          <template v-if="!isPwaEnabled && route.key === 'about-us'">
            <el-menu-item
              index="install-pwa"
              class="menu-without-href lad-install-pwa"
              @click="handleInstallPwa"
            >
              <span>{{ $t('pwa.install_manually_pwa') }}</span>
            </el-menu-item>
          </template>
        </client-only>
      </el-submenu>

      <LadNavigationMenuItem v-else :key="`b-${route.key}`" :route="route" />
    </template>
  </el-menu>
</template>

<script>
import { mapState } from 'vuex'
import { generateTitle } from '@/utils/i18n'
import menu from '@/db/menu'
import LadNavigationMenuItem from './Item'

export default {
  name: 'LadNavigationMenuMain',
  components: { LadNavigationMenuItem },
  props: {
    mode: { type: String, required: true },
  },
  data() {
    return {
      menu,
    }
  },
  computed: {
    ...mapState('app', {
      isPwaEnabled: (state) => state.pwa.isEnabled,
    }),
  },
  methods: {
    hasChildren(item) {
      return item.children && item.children.length
    },
    generateTitle,
    handleInstallPwa() {
      this.$store.dispatch('app/setInstallManually', true)
    },
  },
}
</script>

<style lang="scss">
.lad-navigation--menu--main {
  .el-menu-item {
    i.fas {
      color: #199b39;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 14px; /* 100% */
      margin-right: 8px;
      min-width: 18px;
      display: inline-block;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      color: #4c4f56;
    }
  }
}
</style>
