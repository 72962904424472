var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lad-destinations-menu--continent"},[_c('el-menu-item',{staticClass:"continent-title-section",attrs:{"index":_vm.code}},[_c('nuxt-link',{attrs:{"to":{
        name: 'search-type',
        params: {
          type: _vm.$t('router.search_type.type.continent'),
          value: _vm.slug,
          tab: _vm.$t('router.search_type.tab.tours'),
        },
      }},nativeOn:{"click":function($event){return _vm.handleLoading.apply(null, arguments)}}},[_c('p',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.name))])])],1),_vm._v(" "),_c('div',{staticClass:"desktop-view"},[_vm._l((_vm.countries),function(country,i){return _c('LadDestinationSubmenuItem',_vm._b({key:country,class:5 < i + 1 ? 'hidden-country' : ''},'LadDestinationSubmenuItem',_vm.countriesList[country],false))}),_vm._v(" "),(_vm.countries.length > 5)?_c('div',{staticClass:"show-all-section"},[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleClick(_vm.code)}}},[_vm._v("\n        "+_vm._s(_vm.$t('general.show_all'))+"\n      ")])],1):_vm._e()],2),_vm._v(" "),_c('client-only',[_c('LadLimiter',{staticClass:"mobile-view",attrs:{"component":_vm.$options.components.LadDestinationSubmenuItem,"items":Object.values(_vm.countriesList),"hide-less-btn":"","btn-align":"left","btn-size":"small","btn-type":"text","limit":5}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }