const menu = [
  // {
  //   key: 'about-us',
  //   path: '/about-us',
  //   icon: 'fas fa-address-card',
  //   meta: {
  //     title: 'about_us',
  //   },
  //   children: [
  //     {
  //       key: 'about-us',
  //       path: '/about-us',
  //       meta: {
  //         title: 'about_us',
  //       },
  //     },
  //     {
  //       key: 'why-choose-us',
  //       path: '/why-choose-us',
  //       meta: {
  //         title: 'why_choose_us',
  //       },
  //     },
  //     {
  //       key: 'blog',
  //       path: `${process.env.APP_URL}/blog`,
  //       meta: {
  //         title: 'blog',
  //       },
  //     },
  //     {
  //       key: 'contact-us',
  //       path: '/contact-us',
  //       meta: {
  //         title: 'contact_us',
  //       },
  //     },
  //     {
  //       key: 'customer-benefits',
  //       path: '/customer-benefits',
  //       meta: {
  //         title: 'customer_benefits',
  //       },
  //     },
  //   ],
  // },
  {
    key: 'outlet',
    path: '/outlet',
    icon: 'fas fa-tags',
    meta: {
      title: 'outlet_index',
    },
  },
]

export default menu
