<template>
  <div class="lad-navigation--menu">
    <div class="lad-navigation--menu--wrapper">
      <LadDestinationsMenu
        :mode="mode"
        class="lad-navigation--menu--destinations"
      />

      <el-divider class="divider__destinations"></el-divider>

      <LadNavigationMenuMain :mode="mode" class="lad-navigation--menu--main" />

      <LadNavigationMenuContact
        :mode="mode"
        class="lad-navigation--menu--contact"
      />

      <template
        v-if="
          !isIOS && hasDeferredPrompt && !isPwaEnabled && mode === 'vertical'
        "
      >
        <LazyLoading
          :key="`onlypwa`"
          :loaded="lazy.onlypwa"
          @loaded="(e) => (lazy.onlypwa = e)"
        >
          <LadNavigationMenuPwa
            v-if="lazy.onlypwa"
            :mode="mode"
            class="lad-navigation--menu--pwa"
          />
        </LazyLoading>
      </template>

      <LadNavigationMenuShoppingCart
        v-if="mode === 'horizontal'"
        :mode="mode"
        class="lad-navigation--menu--shopping-cart"
      />
    </div>

    <LadNavigationMenuAuth
      :mode="mode"
      :class="`lad-navigation--menu--auth ${`lad-navigation--menu--auth__${mode}`}`"
    />

    <el-divider
      v-if="!isAuth"
      class="divider__currency-and-language"
    ></el-divider>

    <LadNavigationMenuCurrency
      :mode="mode"
      class="lad-navigation--menu--currency"
    />

    <LadNavigationMenuLanguage
      :mode="mode"
      class="lad-navigation--menu--language"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import LadDestinationsMenu from './Destinations'
import LadNavigationMenuLanguage from './Language'
import LadNavigationMenuCurrency from './Currency'
import LadNavigationMenuContact from './Contact'
import LadNavigationMenuMain from './Main'
import LadNavigationMenuAuth from './Auth'
import LadNavigationMenuShoppingCart from './ShoppingCart'
import LadNavigationMenuPwa from './install-pwa'

export default {
  name: 'LadNavigationMenu',
  components: {
    LadDestinationsMenu,
    LadNavigationMenuLanguage,
    LadNavigationMenuCurrency,
    LadNavigationMenuContact,
    LadNavigationMenuMain,
    LadNavigationMenuAuth,
    LadNavigationMenuPwa,
    LadNavigationMenuShoppingCart,
  },
  props: {
    mode: { type: String, default: 'horizontal' },
  },
  data() {
    return {
      lazy: { onlypwa: false },
    }
  },
  computed: {
    ...mapState('app', {
      isPwaEnabled: (state) => state.pwa.isEnabled,
      hasDeferredPrompt: (state) => !!state.pwa.deferredPrompt,
    }),
    ...mapGetters('device', ['isMobile', 'isIOS']),
  },
}
</script>

<style lang="scss">
.lad-navigation--menu {
  height: 100%;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    height: unset;
  }

  .el-menu-item {
    padding: 0 !important;

    &.menu-without-href span,
    a {
      display: block;
      padding: 0 20px;
    }

    @media screen and (max-width: 768px) {
      a {
        display: block;
        // padding-left: 0;
      }
    }
  }

  .el-submenu {
    .el-menu-item {
      padding: 0 !important;

      &.menu-without-href span,
      a {
        display: block;
        padding: 0 40px;
      }
    }
  }

  .lad-navigation--menu--shopping-cart {
    @media screen and (max-width: 768px) {
      a {
        padding-left: 0;
      }
    }
  }

  .lad-navigation--menu--pwa .lad-install-pwa,
  .lad-auth-item,
  .lad-trips-item {
    padding: 0 20px !important;
  }

  .lad-navigation--menu--pwa .lad-install-pwa.is-active,
  .lad-trips-item.is-active {
    color: #303133;
  }

  p.menu-title {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    line-height: 50px;
  }

  .divider__destinations {
    height: 2px;
    margin: 0 0 0 20px;
    width: calc(100% - 40px);

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      display: none;
    }
  }

  .divider__currency-and-language {
    height: 2px;

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      display: none;
    }
  }
}
</style>
