<template>
  <el-menu :mode="mode" @select="handleSelect">
    <el-submenu v-if="currencies.length" index="currency">
      <template slot="title">
        <div class="currency-menu__container">
          <span class="currency-menu__container_title">{{
            $t('general.currency')
          }}</span>
          <span class="currency-menu__container_value">{{ currency }}</span>
        </div>
      </template>
      <client-only>
        <el-menu-item
          v-for="currency in currencies"
          :key="currency.code"
          :disabled="currency.code === currency"
          :index="currency.code"
        >
          <span>{{ currency.code }}</span>
        </el-menu-item>
      </client-only>
    </el-submenu>

    <el-menu-item v-else :index="currency">
      <div class="currency-menu__container px-20">
        <span class="currency-menu__container_title">{{
          $t('general.currency')
        }}</span>
        <span class="currency-menu__container_value">{{ currency }}</span>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'

export default {
  name: 'LadNavigationMenuCurrency',
  props: {
    mode: { type: String, required: true },
  },
  computed: {
    ...mapState('settings', ['currencies', 'currency']),
  },
  methods: {
    async handleSelect(key, path) {
      if (this.isAuth) {
        this.$axios
          .put(`/api/v1.5/users/update/currency`, { code: key })
          .then((response) => {
            if (response.status === 'success') {
              this.$router.go()
            }
          })
      } else {
        await Cookies.set('currency', key)
        this.$router.go()
      }
    },
  },
}
</script>

<style lang="scss">
.lad-navigation--menu--currency {
  .currency-menu__container {
    display: flex;
    max-width: calc(100% - 30px);
    justify-content: space-between;

    &.px-20 {
      padding: 0 20px;
      max-width: 100%;
    }

    &_title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      color: #4c4f56;
    }

    &_value {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      color: #4c4f56;
      opacity: 0.5;
    }
  }

  i.el-submenu__icon-arrow.el-icon-arrow-down {
    font-size: 16px;
    color: #4c4f56;
    font-weight: bold;
  }
}
</style>
