<template>
  <el-menu v-if="hasContactInfo" :mode="mode" class="contact-menu-wrapper">
    <el-submenu
      index="contact"
      popper-class="lad-navigation--menu--contact--popper"
    >
      <template slot="title">
        <i class="fa fa-info-circle contact-menu__icon" />
        <span class="contact-menu__title">{{
          $t(`${base}.customer_service`)
        }}</span>
      </template>

      <client-only>
        <el-menu-item v-for="section in sections" :key="section">
          <p class="menu-title">{{ $t(`${base}.${section}`) }}:</p>
          <a
            :href="contactInfo[section].phone | phoneHref"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-phone" />
            <span>{{ contactInfo[section].phone }}</span>

            <p v-if="section === 'customer_service'" class="emergency-call">
              <i class="fas fa-info-circle" />
              {{ $t(`${base}.emergency_call`) }}
            </p>
          </a>

          <div v-if="section === 'customer_service'" class="internet-call">
            <i class="fas fa-headphones-alt" />
            <a
              :href="contactInfo.toky.emergency"
              target="_blank"
              rel="noreferrer"
              >{{ $t(`${base}.call_through_internet`) }}
              <i class="fas fa-external-link-alt"
            /></a>

            <el-divider />
          </div>

          <a
            v-for="whatsapp in contactInfo[section].whatsapp"
            :key="whatsapp"
            :href="whatsapp | whatsappHref"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fab fa-whatsapp" />
            <span>{{ whatsapp }}</span>
          </a>

          <a
            :href="contactInfo[section].email | emailHref"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-envelope" />
            <span>{{ contactInfo[section].email }}</span>
          </a>

          <div class="schedule--wrapper">
            <i class="fa fa-clock" />
            <span>{{ $t(`${base}.attention_schedule`) }}</span>
            <p
              v-for="item in contactInfo[section].schedules"
              :key="item"
              class="schedule"
              >{{ item }}</p
            >
          </div>
        </el-menu-item>
      </client-only>
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapState } from 'vuex'
import { phoneHref, whatsappHref, emailHref } from '@/utils'

export default {
  name: 'LadNavigationMenuContact',
  filters: { phoneHref, whatsappHref, emailHref },
  props: {
    mode: { type: String, required: true },
  },
  data() {
    return {
      base: 'navbar',
      sections: ['customer_service', 'sales'],
      contactInfo: [],
    }
  },
  computed: {
    ...mapState('settings', ['locale']),
    hasContactInfo() {
      return !!this.contactInfo && Object.keys(this.contactInfo).length > 0
    },
  },
  watch: {
    locale: {
      immediate: true,
      handler(value) {
        this.contactInfo = require(`@/db/contact/${value.toLowerCase()}`).default
      },
    },
  },
}
</script>

<style lang="scss">
.contact-menu-wrapper {
  & > .el-submenu {
    line-height: 89px;
    height: 100%;
  }
}
.lad-navigation--menu--contact,
.lad-navigation--menu--contact--popper {
  .el-menu-item {
    cursor: default;
    color: #909399;

    .schedule {
      padding-left: 26px;

      &--wrapper {
        line-height: 2.2em;
      }
    }

    .emergency-call {
      display: block;
      font-size: 0.9em;
      font-style: italic;
      line-height: 1.4;
      padding: 0 !important;
      margin: 0;

      .fas {
        font-size: 0.9em;
        color: #909399;
      }
    }

    .internet-call {
      line-height: 2;
      font-size: 0.9em;
      margin-top: 5px;

      a {
        display: inline !important;
        line-height: inherit;
        font-style: italic;

        .fas {
          font-size: 0.7em;
          color: #909399;
        }
      }

      .el-divider {
        margin: 10px 0;
      }
    }

    i {
      font-size: 1.2em;
      margin-right: 5px;
    }

    a {
      display: block;
      line-height: 2.2em;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      line-height: 2.2em;
    }

    p:last-child {
      padding-bottom: 15px;
    }

    &.is-active {
      color: #303133;
    }
  }

  .contact-menu__icon {
    color: #409eff;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 14px; /* 87.5% */
    margin-right: 8px;
    min-width: 18px;
    display: inline-block;
  }

  .contact-menu__title {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    color: #4c4f56;
  }

  i.el-submenu__icon-arrow.el-icon-arrow-down {
    font-size: 16px;
    color: #4c4f56;
    font-weight: bold;
  }
}
</style>
