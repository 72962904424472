<template>
  <el-menu :mode="mode">
    <el-menu-item index="trips" class="lad-install-pwa" @click="handleClick">
      <i class="fa fa-download" />
      <span>{{ $t('pwa.install_manually_pwa') }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'LadNavigationMenuPwa',
  props: {
    mode: { type: String, required: true },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('app/setInstallManually', true)
    },
  },
}
</script>
