<template>
  <el-menu :mode="mode" @select="handleSelect">
    <el-submenu v-if="locales.length" index="language">
      <template slot="title">
        <div class="language-menu__container">
          <span class="language-menu__container_title">{{
            $t('general.language')
          }}</span>
          <span class="language-menu__container_value"
            >{{
              $t(
                // eslint-disable-next-line vue/comma-dangle
                `general.languages_without_country.${currentLanguage}`
              )
            }}
            ({{ currentLanguageCountry }})</span
          >
        </div>
      </template>

      <client-only>
        <el-menu-item
          v-for="language in locales"
          :key="language.code"
          :disabled="language.code === locale"
          :index="language.code"
        >
          <template v-if="isTravelWebsite">
            <span class="lad-navigation--flag">{{ language.flag_code }}</span>
            <span>{{ language.name }}</span>
          </template>
          <a v-else :href="links[language.code]">
            <span class="lad-navigation--flag">{{ language.flag_code }}</span>
            <span>{{ language.name }}</span>
          </a>
        </el-menu-item>
      </client-only>
    </el-submenu>

    <el-menu-item v-else :index="locale">
      <div class="language-menu__container px-20">
        <span class="language-menu__container_title">{{
          $t('general.language')
        }}</span>
        <span class="language-menu__container_value"
          >{{
            $t(
              // eslint-disable-next-line vue/comma-dangle
              `general.languages_without_country.${defaultLanguage}`
            )
          }}
          ({{ defaultLanguageCountry }})</span
        >
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'

export default {
  name: 'LadNavigationMenuLanguage',
  props: {
    mode: { type: String, required: true },
  },
  computed: {
    ...mapState('settings', ['locale', 'locales']),
    defaultLanguage() {
      const data = this.locale.split('_')
      const start = data[0]

      return start
    },
    defaultLanguageCountry() {
      const data = this.locale.split('_')
      const last = data[data.length - 1]

      return last

      // return this.$tc(`general.languages.${data[0]}`, last, {
      //   country: last,
      // })
    },
    currentLanguage() {
      const current = this.locales.find((language) => {
        return language.code === this.locale
      })
      return current ? current.code.split('_')[0] : this.defaultLanguageCountry
    },
    currentLanguageCountry() {
      const current = this.locales.find((language) => {
        return language.code === this.locale
      })
      return current ? current.code.split('_')[1] : this.defaultLanguageCountry
    },
    links() {
      const items = {}

      this.locales.forEach((item) => {
        switch (item.code.toUpperCase()) {
          case 'ES_MX':
            items[item.code] = process.env.APP_URL_ES_MX + this.$route.fullPath
            break
          case 'ES_CO':
            items[item.code] = process.env.APP_URL_ES_CO + this.$route.fullPath
            break
          case 'EN_US':
            items[item.code] = process.env.APP_URL_EN_US + this.$route.fullPath
            break
          default:
            break
        }
      })

      return items
    },
    isTravelWebsite() {
      return process.env.APP_URL === process.env.APP_URL_EN_US
    },
  },
  methods: {
    async handleSelect(key, path) {
      if (this.isTravelWebsite) {
        await Cookies.set('language', key)

        if (this.isAuth) {
          await this.$axios
            .put(`/api/v1.5/users/update/language`, { code: key })
            .then((response) => {
              if (response.status === 'success') {
                this.$router.go()
              }
            })
        } else {
          this.$router.go()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.lad-navigation--menu--language {
  .language-menu__container {
    display: flex;
    max-width: calc(100% - 30px);
    justify-content: space-between;

    &.px-20 {
      padding: 0 20px;
      max-width: 100%;
    }

    &_title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      color: #4c4f56;
    }

    &_value {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      color: #4c4f56;
      opacity: 0.5;
    }
  }

  i.el-submenu__icon-arrow.el-icon-arrow-down {
    font-size: 16px;
    color: #4c4f56;
    font-weight: bold;
  }
}
</style>
