<template>
  <div class="lad-destinations-menu--continent">
    <el-menu-item :index="code" class="continent-title-section">
      <nuxt-link
        :to="{
          name: 'search-type',
          params: {
            type: $t('router.search_type.type.continent'),
            value: slug,
            tab: $t('router.search_type.tab.tours'),
          },
        }"
        @click.native="handleLoading"
      >
        <p class="menu-title">{{ name }}</p>
      </nuxt-link>
    </el-menu-item>

    <div class="desktop-view">
      <LadDestinationSubmenuItem
        v-for="(country, i) in countries"
        :key="country"
        v-bind="countriesList[country]"
        :class="5 < i + 1 ? 'hidden-country' : ''"
      />

      <div v-if="countries.length > 5" class="show-all-section">
        <el-button type="text" size="small" @click="handleClick(code)">
          {{ $t('general.show_all') }}
        </el-button>
      </div>
    </div>

    <client-only>
      <LadLimiter
        class="mobile-view"
        :component="$options.components.LadDestinationSubmenuItem"
        :items="Object.values(countriesList)"
        hide-less-btn
        btn-align="left"
        btn-size="small"
        btn-type="text"
        :limit="5"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LadDestinationSubmenuItem from './DestinationSubmenuItem'

export default {
  name: 'LadDestinationContinent',
  components: {
    LadDestinationSubmenuItem,
    LadLimiter: () =>
      import(/* webpackChunkName: "lad-limiter" */ '@/components/limiter'),
  },
  props: {
    code: { type: String, required: true },
    name: { type: String, required: true },
    slug: { type: String, required: true },
    countries: { type: Array, required: true },
  },
  computed: {
    ...mapState('settings', {
      countriesList(state) {
        const items = {}

        this.countries.forEach((code) => {
          const country = state.countries.find((t) => t.alpha_3_code === code)

          items[code] = {
            alpha_3_code: country.alpha_3_code,
            slug: country.slug,
            name: country.name,
          }
        })

        return items
      },
    }),
  },
  methods: {
    handleLoading() {
      this.$nuxt.$emit('custom-loading', { enabled: true, source: 'search' })
    },
    handleClick(code) {
      this.$emit('click', code)
    },
  },
}
</script>

<style lang="scss">
.lad-destinations-menu--continent {
  .continent-title-section {
    border-bottom: 1px solid #ebeef2;
  }

  .show-all-section {
    border-top: 1px solid #ebeef2;
    padding: 0 10px;
  }

  .mobile-view {
    display: none;
  }

  .el-menu-item {
    padding: 0 !important;

    a {
      display: block;
      padding: 0 10px;
    }
  }

  .desktop-view {
    .hidden-country {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .desktop-view {
      display: none;
    }

    .mobile-view {
      display: block;
    }
  }
}
</style>
