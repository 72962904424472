<template>
  <el-menu class :mode="mode">
    <el-submenu index="destinations" popper-class="lad-destinations-menu">
      <template slot="title">
        <span class="destinations-menu__title">{{
          $t(`${base}.destinations`)
        }}</span>
      </template>

      <LazyHydrate when-visible>
        <el-container>
          <el-main>
            <LadDestinationCountries
              v-if="selectedContinent"
              :continent="selectedContinent"
              @click="setContinent"
            />
            <template v-else>
              <LadDestinationContinent
                v-for="continent in continents"
                :key="continent.code"
                v-bind="continent"
                @click="setContinent"
              />
            </template>
          </el-main>
        </el-container>
      </LazyHydrate>
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapState } from 'vuex'
import LadDestinationContinent from './DestinationContinent'
import LadDestinationCountries from './DestinationCountries'

export default {
  name: 'LadDestinationsMenu',
  components: {
    LadDestinationContinent,
    LadDestinationCountries,
  },
  props: {
    mode: { type: String, required: true },
  },
  data() {
    return {
      base: 'navbar',
      /** cuando entras a ver el listado de todos los paises del continente */
      selectedContinent: null,
    }
  },
  computed: {
    ...mapState('settings', ['continents']),
  },
  methods: {
    setContinent(code) {
      this.selectedContinent = code
        ? this.continents.find((c) => c.code === code)
        : null
    },
  },
}
</script>

<style lang="scss">
.lad-destinations-menu {
  padding: 0 20px;
  width: 100%;

  .el-main {
    display: flex;
    padding: 10px;

    & > div {
      flex: 1 1 0;
      height: fit-content;

      & > .el-menu-item {
        color: #2c3e50;
      }

      & > .el-menu-item:hover {
        color: #67c23a;
      }
    }

    & > div + div {
      margin-left: 20px;
    }
  }

  p.menu-title {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    line-height: 36px;
    margin-top: 1em;
  }
}

.lad-navigation--menu--destinations {
  .destinations-menu__title {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    color: #4c4f56;
  }

  i.el-submenu__icon-arrow.el-icon-arrow-down {
    font-size: 16px;
    color: #4c4f56;
    font-weight: bold;
  }
}
</style>
